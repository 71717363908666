import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './component/header';
import HomePage from './pages/home';

const App = () => (
  <div className='app'>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* Add other pages here */}
      </Routes>
    </Router>
  </div>
);

export default App;