import React, { useState, useEffect } from 'react';
import '../App.css';
import { ethers } from 'ethers'
import { Web3Provider } from '@ethersproject/providers';
import MintButton from '../component/mint-button';
import nftaddress from "../abi/address.json"
import nftABI from "../abi/erc721.json"
const chainId = 1

const HomePage = () => {
    const [contract, setContract] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [networkId, setNetworkId] = useState(null);
    const [signer, setSigner] = useState(null)
    const [price, setPrice] = useState(0);
    const [supply, setSupply] = useState(0);
    const [maxSupply, setMaxSupply] = useState(0);
    const [loadingMinting, setLoadingMinting] = useState(false);

    async function loadContract() {
        if (window.ethereum) {
            try {
                const provider = new Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                if (address) {
                    console.log(`Connected with address ${address}`);
                    setIsConnected(true); // set connection status to true
                    setSigner(signer);  
                    const network = await provider.getNetwork();
                    setNetworkId(network.chainId);        
    
                    console.log("LOAD THIS", network.chainId, nftaddress[network.chainId])
                    const contract = new ethers.Contract(nftaddress[network.chainId.toString()], nftABI, signer);
                    setContract(contract);
        
                    const price = await contract.getPrice(address);
                    const supply = await contract.totalSupply();
                    const maxSupply = await contract.MAX_SUPPLY();

                    setPrice(price);
                    setSupply(parseInt(supply));
                    setMaxSupply(parseInt(maxSupply));
                }
            } catch (err) {
                console.error(err)
            }
        }
    }

    useEffect(() => {
        loadContract();
    }, [networkId]);

    useEffect(() => {
        // Then call loadContract every 1 minute
        const intervalId = setInterval(loadContract, 60 * 1000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [])

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
            // Request user to connect their account
            await window.ethereum.request({ method: 'eth_requestAccounts' });

            const provider = new Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            console.log(`Connected with address ${address}`);
            setIsConnected(true); // set connection status to true
            setSigner(signer);

            const network = await provider.getNetwork();
            setNetworkId(network.chainId);
            } catch (error) {
            console.error(error)
            console.error("User rejected request");
            }
        } else {
            console.error("Metamask is not installed");
        }
    };

    const switchNetwork = async () => {
        if (window.ethereum) {
            try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: chainId }], // 0x1 is the chain ID for Ethereum Mainnet
            });
            } catch (switchError) {
                console.error(switchError);
            }
        }
    };

  return (
    <div className='home-page'>
        <div className='left-side'>
            <img src="witch.png"/>
        </div>
        <div className="content-page">
            <div className='content'>
                <h1 className="content-title">Baphomet Genesis</h1>
                <h2 className="content-subtitle">{supply}/{maxSupply} @{parseFloat(parseInt(price)/1e18).toFixed(3)} ETH</h2>
                <br/>
                <img src="/showcase.jpeg" width={300} className="img-showcase"/>
                <br/>
                {
                isConnected ?
                    networkId !== chainId ? <button onClick={switchNetwork}>Switch Network to Ethereum</button> : <MintButton chainId={networkId} signer={signer} setLoadingMint={setLoadingMinting}/>
                : <button onClick={connectWallet}>Connect Wallet</button>
                }
            </div>
        </div>
        <div className='right-side'>
            <img src="witch.png" className="toRotate"/>
        </div>
    </div>
  );
};

export default HomePage;