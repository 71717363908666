import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import Swal from 'sweetalert2';
import nftaddress from "../abi/address.json"
import nftABI from "../abi/erc721.json"

const MintButton = (props) => {
  const [contract, setContract] = useState(null);
  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [qtyMint, setQtyMint] = useState(1);
  const [mintType, setMintType] = useState(3);
  const [maxWLMint, setMaxWLMint] = useState(0);
  const [availFreeMint, setAvailFreeMint] = useState(0);

  async function loadContract() {
    const contract = new ethers.Contract(nftaddress[props.chainId], nftABI, props.signer);
    setContract(contract);
    const address = await props.signer.getAddress();
    if (address) {
        try {
            const price = await contract.getPrice(address);
            const dataMintType = await contract.mintType();
            if (parseInt(dataMintType) == 1) {
                const whitelistData = await contract.whitelistAddr(address);
                const availMint = parseInt(whitelistData.maxMint) - parseInt(whitelistData.minted)
                setMaxWLMint(availMint);
            } else if (parseInt(dataMintType) == 2) {
                const walletMinted = await contract.freeMintAddr(address);
                const maxFreeMinted = await contract.maxFreeMinted();
                const freeMinted = await contract.freeMinted();
                const wlMinted = await contract.wlMinted();

                const availMint = parseInt(maxFreeMinted) - parseInt(walletMinted);
                console.log(parseInt(maxFreeMinted), parseInt(freeMinted), parseInt(walletMinted), parseInt(wlMinted));

                setMaxWLMint(availMint);
                setAvailFreeMint(parseInt(maxFreeMinted) - parseInt(freeMinted) - parseInt(wlMinted))
            }

            setPrice(price);
            setAccount(address);                
            setMintType(parseInt(dataMintType));
        } catch (e) {
            console.error(e)
        }
    }
  }

  useEffect(() => {
    loadContract();
  }, []);

  useEffect(() => {
    loadContract();
  }, [loading]);

  const mint = async () => {
    if (!contract || !account) return;

    setLoading(true);
    props.setLoadingMint(true);
    console.log(qtyMint)
    
    try {
        const tx = await contract.mint(qtyMint, account, {value: price});
        if (tx) {
            Swal.fire('Success!', `Minting was submited! <a href='http://etherscan.io/tx/${tx.hash}' target='_blank'>Check on Explorer</a>`, 'success');
        }
    } catch (err) {
        console.error(err)
        Swal.fire('Error!', 'Minting failed!', 'error');
    } finally {
      setLoading(false);
      props.setLoadingMint(false);
    }
  };
  const increment = () => {
    setQtyMint(qtyMint + 1);
  };

  const decrement = () => {
    if (qtyMint > 0) {
      setQtyMint(qtyMint - 1);
    }
  };

  return (
    <div>
        <small className="text-support">Free Mint Supply : {availFreeMint}</small>
        <br/>
        <small className="text-support">You Eligible {maxWLMint} Free Mint</small>
        <br/>
      {loading ? (
        <p className="text-support">Loading...</p>
      ) : (
        <div>
            <div className="input-group">
                <button className="input-group__button" id="decrement" onClick={decrement}>-</button>
                <input type="number" className="input-group__input" id="quantity" min="1" value={qtyMint} readOnly/>
                <button className="input-group__button" id="increment" onClick={increment}>+</button>
            </div>
            <button onClick={mint}>Mint Now</button>
        </div>
      )}
    </div>
  );
};

export default MintButton;