// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Header = () => (
  <header className="header">
    <div>
      <img src="logo.png" alt="Logo" />
      <nav>
        <ul>
          <li><Link to="/">HOME</Link></li>
          <li><Link to="/">MY NFT</Link></li>
          {/* <li><Link to="/unlock">Unlock</Link></li> */}
          <li><a href="https://penahitam.gitbook.io/penahitam-dead-art-legion/baphomet-nft-genesis" target={"_blank"}>Learn More</a></li>
        </ul>
      </nav>
    </div>
  </header>
);

export default Header;